.App {
  text-align: center;
}
.box{
  display: flex;
  flex-direction: column;
  padding: 0 40px 40px 40px;
}
.left{
  width: 100%;
  height: 60pxs;
  background: #5b7de6;
  ul{
    width: 100%;
    padding-left: 0;
    display: flex;
    li{
      height: 50px;
      padding:0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: #fff;
    }
  }
}


.search{
  padding: 30px
}